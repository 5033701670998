import { Link, StaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from '@emotion/styled';
import range from 'lodash/range';
import { motion } from 'framer-motion';
import linkStripper from '../utils/link-stripper';
import urlParser from '../utils/urlParser';
import Arrow from '../images/assets/faqArrow.svg';

import Menu from './menu';

import Star from '../images/assets/star.svg';

//Logo Import
// import CrimsonBlack from '../images/assets/Logos/crimson_black.png';
// import CrimsonWhite from '../images/assets/Logos/crimson_white.png';
import CrimsonBlack from '../images/assets/Logos/reload-s360.svg';
import CrimsonWhite from '../images/assets/Logos/reload-s360.svg';
import TealBlack from '../images/assets/Logos/teal_black.png';
import TealWhite from '../images/assets/Logos/teal_white.png';
import VioletBlack from '../images/assets/Logos/violet_black.png';
import VioletWhite from '../images/assets/Logos/violet_white.png';
import Helmet from 'react-helmet';

const HeaderOuterContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  font-family: DM Sans;
`;

const HeaderOuterContainerFixed = styled(motion.div)`
  width: 100%;
  height: ${props => props.height};
  display: flex;
  align-items: center;
  max-width: 1440px;
  justify-content: space-between;
  z-index: 100;

  @media (min-width: 1500px) {
    align-items: flex-start;
  }
  @media (max-width: 1150px) {
    height: ${props => props.mobileHeight};
  }
  background-color: #fff;
  position: fixed;
  max-width: 100%;
  justify-content: center;
`;

const HeaderInnerContainerFixed = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  svg {
    path {
      fill: #4758eb;
    }
  }
`;

const HeaderContainer = styled.div`
  width: 100%;
  height: 120px;
  display: flex;
  align-items: center;
  max-width: 1440px;
  justify-content: space-between;
  z-index: 100;

  @media (min-width: 1500px) {
    align-items: flex-start;
  }
  @media (max-width: 1150px) {
    height: 80px;
  }
`;

const MobileLogoContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 50px;
  display: none;
  @media (max-width: 800px) {
    display: flex;
    margin-left: 5%;
    height: 100%;
  }

  svg {
    width: 125px;
    margin-top: 5px;
    @media (max-width: 800px) {
      width: 80px;
    }
  }
`;

const LogoContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 50px;
  @media (min-width: 1500px) {
    margin-left: 0px;
  }
  @media (max-width: 1150px) {
    margin-left: 5%;
  }
  @media (max-width: 800px) {
    display: none;
  }

  svg {
    width: 125px;
    margin-top: 5px;

    @media (max-width: 800px) {
      width: 80px;
    }
  }
`;

const MenuContainer = styled.div`
  display: flex;
  @media (max-width: 1150px) {
    width: 100%;
    justify-content: flex-end;
  }
`;

const MenuItemContainer = styled.div`
  display: flex;
  @media (max-width: 1150px) {
    display: none;
  }
`;

const MenuItem = styled.div`
  font-size: 13px;
  line-height: 59px;
  color: #404040;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 800;
  text-transform: uppercase;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const MenuItemDark = styled.div`
  font-size: 13px;
  line-height: 59px;
  color: #404040;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 800;
  text-transform: uppercase;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const MenuDivider = styled.div`
  background-color: #404040;
  width: 1px;
  height: 13px;
  margin-left: 25px;
  margin-right: 25px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const MenuDividerDark = styled.div`
  background-color: #404040;
  width: 1px;
  height: 13px;
  margin-left: 25px;
  margin-right: 25px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const LogoImg = styled.img`
  width: 125px;
  margin-top: 5px;
  @media (max-width: 800px) {
    width: 80px;
  }
`;

const ReviewBarContainer = styled(motion.a)`
  display: none !important;
  height: 40px;
  width: 100vw;
  background-color: #4758eb;
  cursor: pointer;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  svg {
    path {
      fill: #fff !important;
    }
  }

  @media (max-width: 768px) {
    flex-wrap: wrap;
    height: 40px;
  }
  @media (max-width: 450px) {
    height: unset;
    padding-top: 10px;
    padding-bottom: 10px;
    min-height: 40px;
    max-width: 100%;
  }
`;

const StarContainerOuter = styled.div`
  margin-right: 20px;
  @media (max-width: 768px) {
    margin-right: 0px;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: -5px;
  }
`;

const StarContainer = styled.div``;

const HeaderMasterContainer = styled.div`
  width: 100%;
`;

const ReviewTextContainer = styled.div`
  font-family: DM Sans;
  font-weight: bold;
  font-size: 12px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  @media (max-width: 768px) {
    font-size: 14px;
  }
  @media (max-width: 550px) {
    font-size: 9px;
    padding-right: 5px;
    padding-left: 5px;
    line-height: 11px;
    margin-top: 6px;
  }
`;

const ArrowContainer = styled(motion.div)`
  height: 10p;
  width: 10px;
  margin-left: 5px;
  @media (max-width: 768px) {
    height: 8.5px;
  }
  @media (max-width: 330px) {
    display: none;
  }
`;

const fixedHeaderAnim = {
  hidden: {
    opacity: 0,
    translateY: -120,
    transition: {
      delay: 0,
      duration: 0.15,
    },
  },
  visible: {
    opacity: 1,
    translateY: 0,
    transition: {
      delay: 0,
      duration: 0.25,
    },
  },
};

const arrowAnim = {
  hidden: {
    translateX: 0,
  },
  visible: {
    translateX: 5,
  },
};

export class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      visible: false,
      theposition: 0,
      positionExact: 0,
      lastPostion: 0,
      showingHeader: false,
      visibilitySensorActive: true,
      fixed: false,
      fixedHeaderAnimState: false,
      barHover: false,
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.listenToScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.listenToScroll);
  }

  handleVisibilityChange = () => {
    if (this.state.barHover === true) {
      this.setState({
        barHover: false,
      });
    } else {
      this.setState({
        barHover: true,
      });
    }
  };

  handleVisibilityChangeForced = () => {
    this.setState({
      barHover: false,
    });
  };

  listenToScroll = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;

    const scrolled = winScroll / height;

    const scrolledRound = Math.round(scrolled * 100) / 100;

    if (scrolledRound < 0.015) {
      this.setState({
        fixed: false,
      });
    }

    if (scrolledRound < this.state.positionExact) {
      this.setState({
        theposition: scrolledRound,
        positionExact: scrolledRound,
      });
      if (scrolledRound > 0.015) {
        this.setState({
          fixed: true,
          fixedHeaderAnimState: true,
        });
      } else {
        this.setState({
          fixedHeaderAnimState: false,
          showingHeader: true,
        });
        setTimeout(() => {
          this.setState({
            fixed: false,
          });
        }, 400);
        setTimeout(() => {
          this.setState({
            showingHeader: false,
          });
        }, 1000);
      }
    } else if (scrolledRound === this.state.positionExact) {
      //do nothing
    } else {
      if (!this.state.showingHeader) {
        this.setState({
          fixedHeaderAnimState: false,
          theposition: scrolledRound,
          positionExact: scrolledRound,
        });
        setTimeout(() => {
          this.setState({
            fixed: false,
          });
        }, 400);
      }
    }
  };

  render() {
    return (
      <HeaderMasterContainer>
        {this.state.fixed ? null : (
          <div>
            <Helmet>
              <script type="application/ld+json">
                {`
                    "@context": "http://schema.org/",
                    "aggregateRating": { 
                        "@type": "AggregateRating", 
                        "ratingCount": "45", 
                        "ratingValue": "5.0"
                    }
                `}
              </script>
            </Helmet>

            {this.props.display_review_bar === 'true' ? (
              <ReviewBarContainer
                href="/why-reload/"
                onHoverStart={this.handleVisibilityChange}
                onHoverEnd={this.handleVisibilityChangeForced}
              >
                <StarContainerOuter>
                  <StarContainer>
                    {range(0, parseInt(this.props.headerData.star_count)).map(
                      index => (
                        <Star
                          key={index}
                          fill={'#FFFFFF'}
                          style={{
                            marginLeft: '1.5px',
                            marginRight: '1.5px',
                          }}
                        />
                      )
                    )}
                  </StarContainer>
                </StarContainerOuter>
                <ReviewTextContainer>
                  {this.props.headerData.review_bar_text.text}
                  <ArrowContainer
                    variants={arrowAnim}
                    animate={this.state.barHover ? 'visible' : 'hidden'}
                  >
                    <Arrow fill={'#FFFFFF'} height={'8'} />
                  </ArrowContainer>
                </ReviewTextContainer>
              </ReviewBarContainer>
            ) : null}
          </div>
        )}
        {this.state.fixed ? (
          <HeaderOuterContainerFixed
            id="fixedheaderId"
            variants={fixedHeaderAnim}
            initial="hidden"
            animate={this.state.fixedHeaderAnimState ? 'visible' : 'hidden'}
            height={
              this.props.display_review_bar === 'true' ? '120px' : '120px'
            }
            mobileHeight={
              this.props.display_review_bar === 'true' ? '120px' : '80px'
            }
            backgroundColor={
              this.props.fixed_background_transparent_mode === 'true'
                ? '#0000'
                : this.props.fixed_background_color
                ? this.props.fixed_background_color
                : '#FFFFFF'
            }
          >
            <HeaderInnerContainerFixed>
              {this.props.display_review_bar === 'true' ? (
                <ReviewBarContainer
                  href="/why-reload/"
                  onHoverStart={this.handleVisibilityChange}
                  onHoverEnd={this.handleVisibilityChangeForced}
                >
                  <StarContainerOuter>
                    <StarContainer>
                      {range(0, parseInt(this.props.headerData.star_count)).map(
                        index => (
                          <Star
                            key={index}
                            fill={'#FFFFFF'}
                            style={{
                              marginLeft: '1.5px',
                              marginRight: '1.5px',
                            }}
                          />
                        )
                      )}
                    </StarContainer>
                  </StarContainerOuter>
                  <ReviewTextContainer>
                    {this.props.headerData.review_bar_text.text}
                    <ArrowContainer
                      variants={arrowAnim}
                      animate={this.state.barHover ? 'visible' : 'hidden'}
                    >
                      <Arrow fill={'#FFFFFF'} height={'8'} />
                    </ArrowContainer>
                  </ReviewTextContainer>
                </ReviewBarContainer>
              ) : null}

              <HeaderContainer>
                <LogoContainer>
                  <a
                    href={this.props.logo_link.url}
                    target={this.props.logo_link.target}
                  >
                    {this.props.fixed_menu_logo === 'crimson_black' ? (
                      <CrimsonBlack />
                    ) : null}
                    {this.props.fixed_menu_logo === 'crimson_white' ? (
                      <CrimsonWhite />
                    ) : null}
                    {this.props.fixed_menu_logo === 'teal_black' ? (
                      <CrimsonBlack />
                    ) : null}
                    {this.props.fixed_menu_logo === 'teal_white' ? (
                      <CrimsonWhite />
                    ) : null}
                    {this.props.fixed_menu_logo === 'violet_black' ? (
                      <CrimsonBlack />
                    ) : null}
                    {this.props.fixed_menu_logo === 'violet_white' ? (
                      <CrimsonWhite />
                    ) : null}
                  </a>
                </LogoContainer>
                <MobileLogoContainer>
                  <a
                    href={this.props.logo_link.url}
                    target={this.props.logo_link.target}
                  >
                    <CrimsonWhite />
                  </a>
                </MobileLogoContainer>
                <MenuContainer>
                  <Menu
                    data={this.props.menuData}
                    handleMenuLoading={this.props.handleMenuLoading}
                    header_items_color={this.props.header_items_color}
                    mobile_header_items_color={
                      this.state.fixed
                        ? this.props.fixed_mobile_header_items_color
                        : this.props.mobile_header_items_color
                    }
                    fixed_mobile_header_items_color={
                      this.props.fixed_mobile_header_items_color
                    }
                    menu_logo={this.props.menu_logo}
                  />
                </MenuContainer>
              </HeaderContainer>
            </HeaderInnerContainerFixed>
          </HeaderOuterContainerFixed>
        ) : (
          <HeaderOuterContainer>
            <HeaderContainer>
              <LogoContainer>
                <a
                  href={this.props.logo_link.url}
                  target={this.props.logo_link.target}
                >
                  {this.props.logo === 'crimson_black' ? (
                    <CrimsonBlack />
                  ) : null}
                  {this.props.logo === 'crimson_white' ? (
                    <CrimsonWhite />
                  ) : null}
                  {this.props.logo === 'teal_black' ? <CrimsonBlack /> : null}
                  {this.props.logo === 'teal_white' ? <CrimsonWhite /> : null}
                  {this.props.logo === 'violet_black' ? <CrimsonBlack /> : null}
                  {this.props.logo === 'violet_white' ? <CrimsonWhite /> : null}
                </a>
              </LogoContainer>
              <MobileLogoContainer>
                <a
                  href={this.props.logo_link.url}
                  target={this.props.logo_link.target}
                >
                  {this.props.logo === 'crimson_white' ? (
                    <CrimsonWhite />
                  ) : this.props.mobile_logo === 'crimson_black' ? (
                    <CrimsonBlack />
                  ) : this.props.mobile_logo === 'crimson_white' ? (
                    <CrimsonWhite />
                  ) : this.props.mobile_logo === 'teal_black' ? (
                    <CrimsonBlack />
                  ) : this.props.mobile_logo === 'teal_white' ? (
                    <CrimsonWhite />
                  ) : this.props.mobile_logo === 'violet_black' ? (
                    <CrimsonBlack />
                  ) : this.props.mobile_logo === 'violet_white' ? (
                    <CrimsonWhite />
                  ) : null}
                </a>
              </MobileLogoContainer>
              <MenuContainer>
                <Menu
                  data={this.props.menuData}
                  desktop_logo_colour={this.props.logo}
                  handleMenuLoading={this.props.handleMenuLoading}
                  fixed_menu_items_color={
                    this.state.fixed
                      ? this.props.fixed_menu_items_color
                      : this.props.header_items_color
                  }
                  mobile_header_items_color={
                    this.state.fixed
                      ? this.props.fixed_mobile_header_items_color
                      : this.props.mobile_header_items_color
                  }
                />
              </MenuContainer>
            </HeaderContainer>
          </HeaderOuterContainer>
        )}
      </HeaderMasterContainer>
    );
  }
}

export default Header;
